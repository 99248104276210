<template>
  <v-row>
    <v-col lg="4">
      <v-dialog v-if="dialog" v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">
            Agregar
          </v-card-title>

          <v-col>
            <s-text autofocus label="DNI" v-model="document"></s-text
          ></v-col>
          <v-col> <s-date label="Fecha" v-model="date"></s-date> </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="
                dialog = false;
                clearForm();
              "
            >
              Cancelar
            </v-btn>
            <v-btn color="primary" @click="dialog = addItem()">
              Agregar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card style="" class="s-card-border-top" elevation="0">
        <v-toolbar class="s-card-title" dense rounded elevation="0" style="">
          <v-toolbar-title class="">Programación </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text x-small @click="deleteItem()">
            <v-icon color="error">mdi-minus-circle-outline</v-icon>
          </v-btn>
          <v-btn text x-small @click="dialog = true">
            <v-icon color="success">mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-col>
          <v-row justify="center">
            <v-radio-group v-model="radioMenu" row hide-details="">
              <v-radio label="Trabajadores" value="1"></v-radio>
              <v-radio
                label="Inscripciones"
                value="2"
              ></v-radio> </v-radio-group
          ></v-row>

          <v-row
            ><v-col>
              <s-import-excel
                @clear="items = []"
                @changeData="changeFile($event)"
              ></s-import-excel
            ></v-col>
          </v-row>

          <v-row>
            <v-data-table
              dense
              :single-select="true"
              :headers="headers"
              @click:row="rowClick"
              :items="items"
              locale="es-ES"
              selectable-key="isSelectable"
              :items-per-page="5"
              item-key="ID"
              v-model="selected"
              :footer-props="{
                showCurrentPage: true,
                showPageText: false,
                showFirstLastPage: false,
                showPerPageOptions: false,
                showPerPageText: false,
                itemsPerPageAllText: 'Todos',
                noResultsText: 'sin resultados',
                noDataText: 'sin resultados',
                itemsPerPageOptions: [10, 25, 50, -1],
                itemsPerPageText: 'Registros',
              }"
            >
              <template slot="no-data">
                Sin Datos
              </template>
              <template slot="no-results">
                Sin Datos
              </template>
              <template v-slot:footer.page-text="{ pageStop, itemsLength }">
                {{ itemsLength }}
              </template>
            </v-data-table>
          </v-row>
          <v-divider></v-divider>
          <v-container>
            <v-row
              ><v-col>
                <v-btn small="" circule block color="primary" @click="save"
                  >Programar</v-btn
                ></v-col
              ></v-row
            >
          </v-container>
        </v-col>
      </v-card>
    </v-col>

    <v-col lg="8" style="padding-left:0px;">
      <s-crud
        excel
        :config="config"
        :refreshTable="refresh"
        saveList
        :filter="filter"
        :heightTable="337"
        remove
        title="Lista de Accesos"
        searchInput
      >
        <template v-slot:TypeSchedule="{ row }">
          <div>
            <v-chip x-small>{{
              row.TypeSchedule == 2 ? "Inscripción" : "Programado"
            }}</v-chip>
          </div>
        </template>
        <template v-slot:filter>
          <v-card elevation="0">
            <v-card-text>
              <v-row justify="center">
                <v-col lg="3"
                  ><s-date
                    label="Fecha Inicio"
                    v-model="filter.AcsBegin"
                  ></s-date>
                </v-col>
                <v-col lg="3"
                  ><s-date label="Fecha Fin" v-model="filter.AcsEnd"></s-date>
                </v-col>
                <v-col lg="4"
                  ><s-select-area
                    label="Area"
                    v-model="filter.AreID"
                  ></s-select-area>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </s-crud>
    </v-col>
  </v-row>
</template>

<script>
import AccessSchedulingService from "./../../services/HumanResource/AccessSchedulingService";
export default {
  name: "MenuType",
  components: {},
  data: () => ({
    refresh: false,
    dialog: false,
    date: "",
    document: "",
    config: {
      model: {
        AcsID: "ID",
        AcsDate: "date",
        AcsFullName: "string",
        TypeSchedule: "",
      },
      service: AccessSchedulingService,
      headers: [
        // { text: "ID", value: "AcsID" ,sortable:false},
        { text: "NOMBRE", value: "AcsFullName", sortable: false },
        { text: "DNI", value: "PrsDocumentNumber", sortable: false },
        { text: "AREA", value: "AreName", sortable: false },
        { text: "CARGO", value: "PstName", sortable: false },
        { text: "DIA", value: "AcsDate", sortable: false },
        { text: "TIPO", value: "TypeSchedule", sortable: false },
      ],
    },
    filter: {
      AcsBegin: null,
      AcsEnd: null,
      PstID: 0,
      AreID: 0,
    },

    selected: [],
    radioMenu: "1",
    page: 1,
    items: [],
    headers: [
      { text: "ID", value: "ID", sortable: false },
      { text: "DNI", value: "PrsDocumentNumber", sortable: false },
      { text: "DÍA", value: "AcsDate", sortable: false },
    ],
  }),
  created() {
    this.filter.AcsBegin = this.$moment().format("YYYY-MM-DD");

    this.filter.AcsEnd = this.$moment().format("YYYY-MM-DD");
  },
  methods: {
    addItem() {
      this.items.push({
        PrsDocumentNumber: this.document,
        AcsDate: this.date,
      });
      this.clearForm();
    },
    clearForm() {
      this.document = "";
      this.date = this.$moment().format("YYYY-MM-DD");
    },
    deleteItem() {
      this.items = this.items.filter(
        (x) => this.row.PrsDocumentNumber != x.PrsDocumentNumber
      );
    },
    rowClick: function(item, row) {
      this.selected = [item];
      this.row = Object.assign({}, item);

      this.$emit("rowSelected", this.selected);
    },
    changeFile(data) {
      let item = {};
      data.forEach((element) => {
        for (var prop in element) {
          if (prop != "DNI") {
            item = {
              PrsDocumentNumber: element.DNI,
              AcsDate: this.$moment(prop).format("YYYY-MM-DD"),
            };
            item.ID = this.items.length + 1;
            this.items.push(item);
          }
        }
      });
      console.log(this.items);
    },
    save() {
      this.items.forEach((element) => {
        element.TypeSchedule = this.radioMenu;
      });
      this.$fun.alert("Seguro de Guardar?", "question").then((val) => {
        if (val.value) {
          AccessSchedulingService.saveMassive(
            this.items,
            this.$fun.getUserID()
          ).then((r) => {
            if (r.status == 200) {
              this.refresh = !this.refresh;
              this.items = [];
              this.$fun.alert("Agregados Correctamente", "success");
            }
          });
        }
      });
    },
  },
};
</script>
