var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"lg":"4"}},[(_vm.dialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Agregar ")]),_c('v-col',[_c('s-text',{attrs:{"autofocus":"","label":"DNI"},model:{value:(_vm.document),callback:function ($$v) {_vm.document=$$v},expression:"document"}})],1),_c('v-col',[_c('s-date',{attrs:{"label":"Fecha"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false;
              _vm.clearForm();}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = _vm.addItem()}}},[_vm._v(" Agregar ")])],1)],1)],1):_vm._e(),_c('v-card',{staticClass:"s-card-border-top",attrs:{"elevation":"0"}},[_c('v-toolbar',{staticClass:"s-card-title",attrs:{"dense":"","rounded":"","elevation":"0"}},[_c('v-toolbar-title',{},[_vm._v("Programación ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.deleteItem()}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-minus-circle-outline")])],1),_c('v-btn',{attrs:{"text":"","x-small":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-plus-circle-outline")])],1)],1),_c('v-col',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-radio-group',{attrs:{"row":"","hide-details":""},model:{value:(_vm.radioMenu),callback:function ($$v) {_vm.radioMenu=$$v},expression:"radioMenu"}},[_c('v-radio',{attrs:{"label":"Trabajadores","value":"1"}}),_c('v-radio',{attrs:{"label":"Inscripciones","value":"2"}})],1)],1),_c('v-row',[_c('v-col',[_c('s-import-excel',{on:{"clear":function($event){_vm.items = []},"changeData":function($event){return _vm.changeFile($event)}}})],1)],1),_c('v-row',[_c('v-data-table',{attrs:{"dense":"","single-select":true,"headers":_vm.headers,"items":_vm.items,"locale":"es-ES","selectable-key":"isSelectable","items-per-page":5,"item-key":"ID","footer-props":{
              showCurrentPage: true,
              showPageText: false,
              showFirstLastPage: false,
              showPerPageOptions: false,
              showPerPageText: false,
              itemsPerPageAllText: 'Todos',
              noResultsText: 'sin resultados',
              noDataText: 'sin resultados',
              itemsPerPageOptions: [10, 25, 50, -1],
              itemsPerPageText: 'Registros',
            }},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"footer.page-text",fn:function({ pageStop, itemsLength }){return [_vm._v(" "+_vm._s(itemsLength)+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"no-data"},[_vm._v(" Sin Datos ")]),_c('template',{slot:"no-results"},[_vm._v(" Sin Datos ")])],2)],1),_c('v-divider'),_c('v-container',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"small":"","circule":"","block":"","color":"primary"},on:{"click":_vm.save}},[_vm._v("Programar")])],1)],1)],1)],1)],1)],1),_c('v-col',{staticStyle:{"padding-left":"0px"},attrs:{"lg":"8"}},[_c('s-crud',{attrs:{"excel":"","config":_vm.config,"refreshTable":_vm.refresh,"saveList":"","filter":_vm.filter,"heightTable":337,"remove":"","title":"Lista de Accesos","searchInput":""},scopedSlots:_vm._u([{key:"TypeSchedule",fn:function({ row }){return [_c('div',[_c('v-chip',{attrs:{"x-small":""}},[_vm._v(_vm._s(row.TypeSchedule == 2 ? "Inscripción" : "Programado"))])],1)]}},{key:"filter",fn:function(){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"lg":"3"}},[_c('s-date',{attrs:{"label":"Fecha Inicio"},model:{value:(_vm.filter.AcsBegin),callback:function ($$v) {_vm.$set(_vm.filter, "AcsBegin", $$v)},expression:"filter.AcsBegin"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('s-date',{attrs:{"label":"Fecha Fin"},model:{value:(_vm.filter.AcsEnd),callback:function ($$v) {_vm.$set(_vm.filter, "AcsEnd", $$v)},expression:"filter.AcsEnd"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('s-select-area',{attrs:{"label":"Area"},model:{value:(_vm.filter.AreID),callback:function ($$v) {_vm.$set(_vm.filter, "AreID", $$v)},expression:"filter.AreID"}})],1)],1)],1)],1)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }